import { BarChart } from "@mui/x-charts";
import { ChatsInfo } from "../types";
import { useEffect, useState } from "react";
import {  sortObjectDesc } from "../../../utils/short";
import { showPorcetage } from "../porcetage";
import { formatLocalCurrency } from "../../../utils/currency";

export function ChatsGraph({chats}:{chats?:Array<ChatsInfo>}){
   const [states,setStates]=useState<any>();
   useEffect(()=>{
       if(!chats)
          return;
       setStates(getNumberOfChatsByState(chats))
   },[chats])
   if(!chats)
     return null;
   return (<div style={{display:'flex',justifyContent:'center',width:'100%'}}>
    <div style={{display:'inline-block'}}> 
     <p>{`Numero de conversas no status. Total de conversas ${states?.total}`}</p>
      <BarChart
        xAxis={[{ scaleType: 'band', data: states?states.keys:[]}]}
        series={[{ data: states?states.values:[],valueFormatter:(v)=>showPorcetage(v,states?.total) }]}
        width={700}
        height={400}
        />
   </div>
   </div>)
}

function getNumberOfChatsByState(chats:Array<ChatsInfo>){
     let states:any={};
     for(let c of chats){
         if(states[c.current_state]){
            states[c.current_state]++;
         }else{
            states[c.current_state]=1;
         }

     }
     const sorted=sortObjectDesc(states);
     console.log(sorted);
     return {
        ...sorted,
        total:chats.length,
     }
}
