import { ReactNode, useEffect, useState } from "react";
import { DisplayCard } from "../../whatsapp/datadisplay";
import { NewClientsInfo } from "../types";

export function DisplayCardClientsInfo({newClients}:{newClients?:NewClientsInfo}){
   const [clientsTooltip,setClientsTooltip]=useState<null|ReactNode>();
   const [savedClientsTooltip,setSavedClientsTooltip]=useState<null|ReactNode>()
   useEffect(()=>{
    if(!newClients)
        return;
    setClientsTooltip(<div>
      <p>{`Total de clientes: ${newClients.totalClients}`}</p>
    </div>);
    setSavedClientsTooltip(<div>
      <p>{`Total clientes (Documento): ${newClients.totalSavedClients}`}</p>
    </div>)
   },[newClients])
   if(!newClients)
      return null;
   
   return (
      <>
         <DisplayCard marginLeft='9px' toolTip={clientsTooltip} primary="Clientes Novos"  secondary={`${newClients?newClients.numberNewClients:0}`}/>
         <DisplayCard marginLeft='9px' toolTip={savedClientsTooltip} primary="Clientes Novos (Documento)"  secondary={`${newClients?newClients.numberNewSavedClients:0}`}/>
      </>
   ) 
}