export function shortDescObjectByKey(data:Array<any>,key:string,normalizeFunction?:(val:any)=>any){
    if(!Array.isArray(data))
       throw "Data param must be an array of object";
    let temp:Array<any>=[];
    temp=temp.concat(data); 
    let ret:Array<any>=[];
    while(temp.length>0){
        const maxIndex=getGreaterIndex(temp,key);
        ret.push(temp[maxIndex]);
        temp=temp.slice(0,maxIndex).concat(temp.slice(maxIndex+1));
    }
    return ret;
    function getGreaterIndex(o:Array<any>,k:string){
       let max=normalizeFunction?normalizeFunction(o[0][k]):o[0][k];
       let index=0;
       for(let i=1;i<o.length;i++){
           let val=normalizeFunction?normalizeFunction(o[i][k]):o[i][k];
           if(val>max){
             index=i;
             max=val;
           }
       }
       return index;
    }
}

export function sortObjectDesc(obj:any){
    let keys=Object.keys(obj);
    let values=Object.values(obj);
    
    const ret:{keys:Array<string>,values:Array<any>}={
        keys:[],
        values:[],
    }
    while(keys.length>0){
        const index=getLowerIndex(values);
        ret.keys.push(keys[index]);
        ret.values.push(values[index]);
        keys=keys.slice(0,index).concat(keys.slice(index+1));
        values=values.slice(0,index).concat(values.slice(index+1));
    }
    return ret;
    function getLowerIndex(data:Array<any>){
        let index=0;
        let min=data[0];
        for(let i=1;i<data.length;i++){
            if(data[i]>min){
                min=data[i];
                index=i;
            }
        }
        return index;
    }
    
}